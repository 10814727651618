.btn {
  appearance: none;
  backface-visibility: hidden;
  border-radius: 20px;
  border-style: none;
  border: 2px solid #e6004c;
  box-shadow: none;
  box-sizing: border-box;
  color: #e6004c;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, -apple-system, system-ui, 'Segoe UI', Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 500;
  height: 50px;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 14px 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
  width: 50%;
  align-self: end;
}
.btn:hover {
  color: #fff;
  background-color: #e6004c;
}
